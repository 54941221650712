<template>
  <div>
    <SiteHeader></SiteHeader>

    <div class="topbar text-center">
      <strong class="fs-1">Select your shipping address and submit.</strong>
      <div class="menu">
            <div class="alink" @click="toCart">My Cart</div>
            <div class="alink current" @click="toOrders">Orders</div>
            <div class="alink">Account</div>
            <div class="alink" @click="toMessages">Messages</div>
            <div class="alink" @click="toAbout">About</div>
      </div>
    </div>

    <div class="container mt-5 mb-5">

      <div class="block mb-3" >
        <h3 class="form-tittle mb-0"><i class="bi-1-square-fill text-success"></i> Order Detail</h3>
        <hr class="my-4" />

        <table class="table mb-0">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Code No.</th>
              <th scope="col">Item Name</th>
              <th scope="col">Quantity</th>
              <th scope="col">UnitPrice</th>
            </tr>
          </thead>
          <tbody class="order-list">
            <tr v-for="(item, index) in detail.order_items">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.code }} - {{ item.dash }}</td>
              <td>
                <a>{{ item.title }}</a>
              </td>
              <td class="goods_num">{{ item.nums }}</td>

              <td>
                $<em class="goods_price">{{ item.price }}</em>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="table-primary">
              <th></th>
              <th></th>
              <th></th>
              <th class="goods_num_sums">{{ detail.total_nums }}</th>
              <th>
                $<em class="goods_price_sums">{{ detail.subamount }}</em>
              </th>
            </tr>
          </tfoot>
        </table>

      </div>

      <div class="alert alert-warning" role="alert">
        Your order include pre-sale item, so you can't pay. We will review and split the order within 48 hours.
      </div>


      <div class="block mb-3 address" >
        <h3 class="form-tittle mb-0"><i class="bi-2-square-fill text-warning"></i> Shipping Address</h3>
        <hr class="my-4" />


        <el-select v-model="ShipType" placeholder="Please select the shipping method first">
          <el-option
            v-for="(option, value) in detail.ShipType"
            :key="value"
            :label="option"
            :value="value">
          </el-option>
        </el-select>


        <el-select v-model="addressId" placeholder="Select one from the history address" v-if="addressId">
          <el-option
            v-for="item in address_list"
            :key="item.id"
            :label="`${item.fName} ${item.lName} | ${item.postcode} | ${item.aDetail}, ${item.aCity}, ${item.aProvince}, ${item.aCountryName}`"
            :value="item.id">
          </el-option>
        </el-select>

        <el-row :gutter="10" >
          <template v-if="address_list.length > 0">
          <el-col :span="12">
            <div class="block">
              <span><i class="bi-buildings"></i><el-text>Company:{{ firstAddress.companyName }} </el-text></span>
              <span><i class="bi-person-vcard"></i><el-text>Consignees: {{firstAddress.fName}} {{firstAddress.lName}}</el-text></span>
              <span><i class="bi-mailbox text-danger"></i><el-text>PostCode: {{firstAddress.postcode}}</el-text></span>
              <span><i class="bi-phone"></i><el-text>Phone: {{firstAddress.phone}} </el-text></span>
              <span><i class="bi-envelope"></i><el-text>Email: {{firstAddress.email}}</el-text></span>
              <span><i class="bi-flag"></i><el-text>Country: {{firstAddress.aCountryName}}</el-text></span>
              <span><i class="bi-house-check"></i><el-text>Address: {{firstAddress.aDetail}}, {{firstAddress.aCity}}, {{firstAddress.aProvince}}</el-text></span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="block" @click="addAddress">
              <span class="text-center"><i class="bi-plus-circle-fill"></i><el-text>create a new one</el-text></span>
            </div>
          </el-col>    
        </template>
        <template v-else>    
          <el-col :span="24">
            <div class="block" @click="addAddress">
              <span class="text-center"><i class="bi-plus-circle-fill"></i><el-text>You haven't created a shipping address yet, add it now!</el-text></span>
            </div>
          </el-col>   
        </template>  
        </el-row>


        <el-dialog :visible.sync="dialogVisible" title="Add New Address">
          <el-form :model="editForm" ref="editForm" label-width="90px">
            <el-form-item label="Country" >
              <el-select v-model="editForm.aCountry"  style="width: 100%;" filterable placeholder="Enter your country's name for a quick search">
                <el-option
                  v-for="item in optionsCountry"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Company" >
              <el-input v-model="editForm.companyName"></el-input>
            </el-form-item>

            <div class="mb-3" style="display: flex; justify-content: space-between; align-items: center; ">
              <el-form-item label="FirstName" class="mb-0" style="width: 48%;">
                <el-input v-model="editForm.fName"></el-input>
              </el-form-item>
              <el-form-item label="LastName" style="width: 48%;">
                <el-input v-model="editForm.lName"></el-input>
              </el-form-item>              
            </div>

            <div class="mb-3" style="display: flex; justify-content: space-between; align-items: center; ">
              <el-form-item label="Email" class="mb-0" style="width: 48%;">
                <el-input v-model="editForm.email" placeholder="Can be left blank"></el-input>
              </el-form-item>
              <el-form-item label="Phone" style="width: 48%;">
                <el-input v-model="editForm.phone" placeholder="Can be left blank"></el-input>
              </el-form-item>          
            </div>
            <el-form-item label="PostCode">
              <el-input v-model="editForm.postcode" placeholder=""></el-input>
            </el-form-item>
            <!--<el-form-item label="Province">
              <el-input v-model="editForm.aProvince"></el-input>
            </el-form-item>-->
            <el-form-item label="City">
              <el-input v-model="editForm.aCity"></el-input>
            </el-form-item>
            <el-form-item label="Detail">
              <el-input v-model="editForm.aDetail"></el-input>
            </el-form-item>
          </el-form>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="primary" @click="saveAddress">Save</el-button>
          </div>
        </el-dialog>


      </div>


      <div class="block mb-3" >
        
        <h3 class="form-tittle mb-0"><i class="bi-3-square-fill text-primary"></i> Order Comments</h3>
        <hr class="my-4" />

        <div class="form-group mb-0">
          <label class="mb-2"
            ><i class="bi-chat-left-dots pe-2"></i> If you have custom
            requirements for your order, please fill in here.</label
          >
          <textarea
            class="form-control"
            rows="3"
            v-model="remarks"
            name="remarks"
          ></textarea>
        </div>

      </div>


      <div class="block mb-3" >

        
        <h3 class="form-tittle mb-0"><i class="bi-4-square-fill text-danger"></i> Payment Info</h3>
        <hr class="my-4" />

        <div class="alert alert-danger mb-0" role="alert">
          <div class="row mb-0">
            <span class="col-md-6">
              Amount：<b>$</b
              ><b class="ajax_order_amount pe-2">{{ detail.subamount }}</b>
              Shipping Fee：
              <em class="text-danger">to be confirm</em>
            </span>
            <span class="col-md-6 text-right" style="align-content: center;"
              >Total Amount：<b>$</b
              ><b class="ajax_total_amount">{{ detail.subamount }}</b></span
            >
          </div>
        </div>

      </div>

        <a class="btn btn-primary" @click="toPlaceOrder">Place Order</a>

    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";
import Foot from "@/components/website/Foot.vue";
import { orderDetail,addressAdd, countryList, placeOrder } from "@/api/index.js";


export default {
  name: "PcWebsiteConfirm",

  components: {
    SiteHeader,
    Foot,
  },

  data() {
    return {
      dialogVisible: false, // 弹窗是否可见
      editForm: {
        optionsCountry: [],
        aCountry: '', // 存储选中的国家
        value: '',
        aCountryName: '',
        companyName: '',
        postcode: '',
        aProvince: '',
        aCity: '',
        aDetail: '',
        fName: '',
        lName: '',
        email: '',
        phone: ''
      }, // 编辑地址的表单数据

      detail: {},
      address_list: [], // 修改初始化为数组
      ShipType: '1', 
      addressId: null, // 选择的记录的ID
      remarks: "",
      count: 0,
      totalPrice: 0,
    };
  },

  computed: {
    token: {
      get() {
        return this.$store.state.user.token;
      },
    },
    userinfo: {
      get() {
        return this.$store.state.user.userinfo;
      },
    },
    firstAddress() {
      // 获取第一条记录
      return this.address_list.find(item => item.id === this.addressId) || {};
    }
  },
  mounted() {
    // 设置默认值为第一条记录的 id
    if (this.address_list.length > 0) {
      this.addressId = this.address_list[0].id;
      //console.log(this.address_list[0].id);
    }
    this.fetchCountries();
    this.getOrderDetail();    
  },
  watch: {
    addressId(newValue) {
      // 监听选中记录的变化，可以在此处进行其他操作
      //console.log('Selected address ID:', newValue);
    }
  },
  methods: {
    //去下单
    toPlaceOrder() {
      const params = {
        orderno: this.$route.params.orderno,
        content: this.remarks,
        addressId: this.addressId,
        shipway: this.ShipType,
      };
      placeOrder(params).then((res) => {
        this.$message.success("Order submission successful");
        this.$router.push("/order/list");
      });
    },
    getOrderDetail() {
      const params = {
        orderno: this.$route.params.orderno,
      };
      orderDetail(params).then((res) => {
        this.detail = res.data;
        this.address_list = this.detail.address_list;

        // 设置默认值为第一条记录的 id
        if (this.address_list.length > 0) {
          this.addressId = this.address_list[0].id;
        }
      });
    },
    fetchCountries() {
      countryList().then(response => {
        // 将接口返回的数据赋值给 options
        this.optionsCountry = response.data;
      }).catch(error => {
        console.error('Failed to fetch countries: ', error);
      });
    },
    addAddress() {
      this.fetchCountries();
      this.editForm = {
        aCountryName: '',
        companyName: '',
        postcode: '',
        aProvince: '',
        aCity: '',
        aDetail: '',
        fName: '',
        lName: '',
        email: '',
        phone: ''
      };
      this.dialogVisible = true; // 打开添加地址的弹窗
    },
    saveAddress() {addressAdd(this.editForm).then(response => {
          // 如果接口调用成功
          if (response.code===1) {
            // 显示成功提示信息
            this.$message.success('Address saved successfully');
            // 关闭弹窗
            this.dialogVisible = false;
            // 刷新当前页面
            window.location.reload();
          } else {
            // 显示失败提示信息
            this.$message.error(response.message || 'Failed to save address');
          }
        })
        .catch(error => {
          // 如果接口调用失败，显示失败提示信息
          this.$message.error(error.message || 'Failed to save address');
        });
    },

    ensureFloat(value) {
      if (typeof value === "string") {
        const floatValue = parseFloat(value);
        // 检查parseFloat是否成功转换成了一个非NaN的浮点数
        if (!isNaN(floatValue)) {
          return floatValue;
        }
      } else if (typeof value === "number" || typeof value === "boolean") {
        // 如果是数字或布尔值，直接返回其对应的数字值
        return Number(value);
      }

      throw new Error(
        "Invalid input provided. Expected a string convertible to a float or a number/boolean."
      );
    },

    getPrice() {
      var that = this;
      return new Promise((resovle) => {
        const params = that.cartdata.map((obj) => obj.id);
        cartprice({ ids: JSON.stringify(params) }).then((res) => {
          const priceArr = res.data;
          const newdata = that.cartdata.map((item) => {
            priceArr.map((resPrice) => {
              if (item.id === resPrice.id) {
                item.price = this.ensureFloat(resPrice.price);
              }
            });
            return item;
          });
          this.cartdata = newdata;
          resovle(newdata);
        });
      });
    },

    /**
     * 计算小计和总价
     */
    countPrice() {
      let total = 0;
      let num = 0;
      this.cartdata.map((item) => {
        if (item.checked) {
          total += item.sub_price;
          num++;
        }
        return item;
      });
      this.totalPrice = total;
      this.checkedNumber = num;
    },

    countSubPrice(cartdata) {
      this.checkedNumber = 0;
      const cdata = cartdata.map((item) => {
        (item.plusDisabled = item.count < item.max ? false : true),
          (item.reduceDisabled = item.count === item.moq ? true : false),
          (item.sub_price = item.price * item.count);
        if (item.checked === undefined) {
          item.checked = false;
        } else {
          if (item.checked === true) {
            this.checkedNumber++;
            this.totalPrice += item.sub_price;
          }
        }
        // item.checked = item.checked||false
        return item;
      });

      return cdata;
    },


    toCart() {
      this.$router.push("/cart");
    },
    toOrders() {
      this.$router.push("/order/list");
    },
    toAccount() {
      this.$router.push("/account");
    },
    toMessages() {
      this.$router.push("/message");
    },
    toAbout() {
      this.$router.push("/about");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
div.block {
  background-color: var(--el-bg-color-page);
  padding:1rem 0.85rem;
  hr {
    border-top: 1px solid var(--el-text-color-secondary);
  }
  i,em {
    font-style: normal;
  }
  .table {
    background-color: var(--el-bg-color);
    td,th {
      background-color: var(--el-bg-color);
      color: var(--el-text-color-primary);
      font-size: 0.95rem;
    }
    tfoot {
      border-width: 0px;
      th {
        border: 0px none;
      }
    }
  }
  textarea.form-control {
    background-color: var(--el-bg-color);
    border: 1px solid var(--el-border-color);
  }
}
.address {
  .el-row {
    display: flex;
    .el-col {
      margin-bottom: 10px;
    }
    .el-col:last-child {
      .block:hover {
        background-color: var(--el-color-primary-light-3);
        color: var(--el-color-primary-light-9);
        
      }
      span {
        font-size: 1.15rem;
        i {
          font-size: 1.8rem;
          display: block;
          margin-bottom: 0px;
        }
      }
    }       
      .block {
        background-color: var(--el-bg-color);
        justify-items: center;
        height: 100%;
        cursor: pointer;
        span {
          display: block;
          font-size: 0.95rem;
          margin-bottom: 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;    
          align-items: center;
          i {
            margin-right: 3px;
          }
        }
        span:last-child {
          margin-bottom: 0px;
        }           
      }
      .block:last-child {
        align-content: center;
      }

  }

}
</style>
<style>
.el-dialog__body {
  padding: 20px;
  .el-form-item:last-child {
    margin-bottom: 0px;
  }
}
.el-input__inner {
  background-color: var(--el-bg-color);
  border: 1px solid var(--el-border-color);
}
.el-select {
  width: 100%;
  margin-bottom: 10px;
}
</style>